import styled from "styled-components";
import { themeGet } from "@styled-system/theme-get";
import tw from "twin.macro";

export const PageHeaderWrapper = styled.div`
  position: relative;
  padding: 1rem;
  text-align: center;
  background-color: white;
  ${tw`border-0 border-b-4 border-gray-200 border-solid`}

  h3 {
  }
`;